// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---gatsby-theme-novela-labbalabba-src-templates-article-template-tsx": () => import("./../../../../gatsby-theme-novela-labbalabba/src/templates/article.template.tsx" /* webpackChunkName: "component---gatsby-theme-novela-labbalabba-src-templates-article-template-tsx" */),
  "component---gatsby-theme-novela-labbalabba-src-templates-articles-template-tsx": () => import("./../../../../gatsby-theme-novela-labbalabba/src/templates/articles.template.tsx" /* webpackChunkName: "component---gatsby-theme-novela-labbalabba-src-templates-articles-template-tsx" */),
  "component---gatsby-theme-novela-labbalabba-src-templates-author-template-tsx": () => import("./../../../../gatsby-theme-novela-labbalabba/src/templates/author.template.tsx" /* webpackChunkName: "component---gatsby-theme-novela-labbalabba-src-templates-author-template-tsx" */),
  "component---gatsby-theme-novela-labbalabba-src-templates-category-template-tsx": () => import("./../../../../gatsby-theme-novela-labbalabba/src/templates/category.template.tsx" /* webpackChunkName: "component---gatsby-theme-novela-labbalabba-src-templates-category-template-tsx" */),
  "component---gatsby-theme-novela-labbalabba-src-templates-podcast-template-tsx": () => import("./../../../../gatsby-theme-novela-labbalabba/src/templates/podcast.template.tsx" /* webpackChunkName: "component---gatsby-theme-novela-labbalabba-src-templates-podcast-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

